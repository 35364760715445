import AbstractService from "./AbstractService";

class RawImportsService extends AbstractService {

  constructor() {
    super("rawimports");
  }

}

export default new RawImportsService();