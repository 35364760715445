import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./ReactUtils";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    navigate("/calculateutilities");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  if (user) {
    const _logout = (msg) => {
      setTimeout(() => {
        console.log('Forcing logout due to: ', msg);
        logout();
      },1);
    }
    if (!user['session_uuid']) {
      _logout('no session');
    } else if (!user['session_expiration']) {
      _logout('session expiration is not set')
    } else {
      const now = new Date().toISOString();
      if (now > user['session_expiration']) {
        _logout('session has expired');
      }
    }
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};