import React from 'react';

function DeleteConfirmationDialog({
                                    message="Are you sure you want to continue?",
                                    handleConfirm,
                                    handleCancel,
                                  }) {
  const _handleDelete = () => {
    if (window.confirm(message)) {
      handleConfirm();
    } else {
      handleCancel();
    }
  };

  return (
    <span style={{marginLeft:"20px"}}>
      <span style={{cursor:"pointer"}} onClick={_handleDelete}>
        <img src="/icons/delete_001.png" width="16" height="16" alt="Delete Record" />
      </span>
    </span>
  );
}

export default DeleteConfirmationDialog;