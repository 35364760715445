import AbstractService from "./AbstractService";

class PWAccountRefService extends AbstractService {

  constructor() {
    super("pwaccountref");
  }

}

export default new PWAccountRefService();