import React from "react";
import {useState,onEnter} from "../../../utils/ReactUtils";
import UsersService from "../../../services/UsersService";
import TextInput from "./etc/TextInput";
import {Button} from "@mui/material";
import Message from "./etc/Message";

const ChangePassword = (props) => {
  const {userId} = props;
  const [isSaving,setIsSaving] = useState(false, 'isSaving');
  const [oldPassword,setOldPassword] = useState("", 'oldPassword');
  const [newPassword,setNewPassword] = useState("",'newPassword');
  const [newPasswordConfirm,setNewPasswordConfirm] = useState("",'newPasswordConfirm')
  const [message,setMessage] = useState(null, 'message');
  const [isError,setIsError] = useState(false, "isError");

  const setError = msg => {setMessage(msg); setIsError(true)};
  const setInfo = msg => {setMessage(msg); setIsError(false)};
  const resetMessage = () => {setMessage(null); setIsError(null);};

  const doSave = async () => {
    resetMessage();
    if (!oldPassword || !newPassword || !newPasswordConfirm) {
      setError("All fields are required");
    } else if (newPassword !== newPasswordConfirm) {
      setError("New password must match its confirmation");
    } else if (newPassword.length < 8) {
      setError("New password must be at least 8 characters");
    } else if (newPassword === oldPassword) {
      setError("New password cannot be the same as the old");
    } else {
      setIsSaving(true);
      const result = (await UsersService.changePassword(userId, oldPassword, newPassword))["data"];
      setIsSaving(false);
      if (result['success']) {
        setInfo(result['message']);
        setOldPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
      } else {
        setError(result['message']);
      }
    }
  };

  const result = isSaving ? <div>Saving...</div> :
    <div>
      <div>
        <TextInput title="Old Password" index="1" isPassword={true}
                   value={oldPassword} setValue={setOldPassword}
                   onKeyDown={e=>onEnter(e,doSave)}
        />
        <TextInput title="New Password" index="2" isPassword={true}
                   value={newPassword} setValue={setNewPassword}
                   onKeyDown={e=>onEnter(e,doSave)}
        />
        <TextInput title="Confirm New Password" index="3" isPassword={true}
                   value={newPasswordConfirm} setValue={setNewPasswordConfirm}
                   onKeyDown={e=>onEnter(e,doSave)}
        />
      </div>
      <div style={{padding:"20px 30px 0px 0px", float:"right"}}>
        <Button onClick={doSave}>Save</Button>
      </div>
    </div>
  ;
  return <div style={{borderStyle:"outset",
    padding:"10px",
    marginLeft:"10px",
    minHeight:"100%",
  }}>
    <h4>Change Password</h4>
    <Message message={message} isError={isError} />
    {result}
  </div>;
};

export default ChangePassword;