import AbstractService from "./AbstractService";

class BuildingsService extends AbstractService {

    constructor() {
        super("buildings");
    }

}

export default new BuildingsService();