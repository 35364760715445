import React from "react";
import Button from '@mui/material/Button';

const CalcError = (parms) => {
  const {
    errorMessage,
    reset,
  } = parms;

  return <div>
    <Button onClick={reset}>Reset</Button>
    <h4>An Error Occurred</h4>
    <div>{errorMessage}</div>
  </div>;
};

export default CalcError;