const billTypes = [
  {id:1,name:"Standard Meter"},
  {id:2,name:"Smart Meter"},
  {id:3,name:"Flat Rate"},
  {id:4,name:"Exempt"},
];

class BillTypeService {

  async get(id = null) {
    if (id) {
      return {data:billTypes[id-1]};
    } else {
      return {data:billTypes};
    }
  }

}

export default new BillTypeService();