import AbstractBaseService from "./AbstractBaseService";

class NotificationsService extends AbstractBaseService {

  constructor() {
    super("notifications");
  }

  async getStagnantAccounts() {
    const result = await this._doGet("/stagnantAccounts");
    return result;
  }
}

export default new NotificationsService();