import TenantsService from "../../../services/TenantsService";
import AbstractMaintain from "./AbstractMaintain";
import * as XLSX from "xlsx";
import {DateTime} from "luxon";

const baseDate = DateTime.local(1899,12,30); // January 1, 1900
const xlsDateFormatter = (xlsDateNumber) => {
  return baseDate.plus({days:xlsDateNumber}).toISO().split('T')[0];
};
const getName = (firstName, lastName) => {
  let name = "";
  if (lastName) {
    name += lastName;
  }
  if (firstName) {
    if (name.length > 0) {
      name += ", ";
    }
    name += firstName;
  }
  return name;
};
const jsonConvertForDisplay = (origJsonArray) => {
  let newArray = [];
  const len = origJsonArray.length;
  for (let i=0; i<len; i++) {
    let json = {...origJsonArray[i]};
    if (json['Start Date']) {
      json['Start Date'] = xlsDateFormatter(json['Start Date']);
    }
    if (json['End Date']) {
      json['End Date'] = xlsDateFormatter(json['End Date']);
    }
    newArray.push(json);
  }
  return newArray;
};
const jsonConvertForSave = (origJsonArray) => {
  let newArray = [];
  const len = origJsonArray.length;
  for (let i=0; i<len; i++) {
    let origJson = origJsonArray[i];
    const newJson = {
      name: getName(origJson['Primary Contact First Name'], origJson['Primary Contact Last Name']),
      lease_id: origJson['Lease ID'],
      lease_name: origJson['Lease Name'],
      phone_number: origJson['Primary Contact Mobile'],
      email: origJson['Primary Contact Email'],
      building_name: origJson['Building'],
      unit_name: origJson['Unit'],
      start_date: origJson['Start Date'],
      end_date: origJson['End Date'],
    };
    newArray.push(newJson);
  }
  return newArray;
};
const MaintainTenants = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Lease ID",
      accessor: "lease_id",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Lease Name",
      accessor: "lease_name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Phone Number",
      accessor: "phone_number",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "Email",
      accessor: "email",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "Unit",
      accessor: "derived_unit_name",
      accessorOverride: "building_unit_id",
      type: "smartselect",
      smartSelectConfig: {
        tiers: ["client","building","building_unit"],
      },
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      type: "yyyymmdd",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      type: "yyyymmdd",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Final Bill Posted",
      accessor: "final_bill_posted",
      type: "toggle",
      isEditable: true,
      isSearchable: false,
      defaultValue: 0,
    },
    {
      Header: "Scheduled Move Out Date",
      accessor: "scheduled_move_out_date",
      type: "yyyymmdd",
      isEditable: true,
      isRequired: false,
      isSearchable: false,
    },
  ];

  const importerConfig = {
    fileTypeAccepts: '.xls, .xlsx',
    convertFileToJson: (file, callbackWithJson) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const parseOptions = {
          type: "array",
          raw: true,
        };
        const workbook = XLSX.read(data, parseOptions);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let maxRow = 0;
        // see stackoverflow:
        // https://stackoverflow.com/questions/30859901/parse-xlsx-with-node-and-create-json
        for (const cell in worksheet) {
          if (cell[0] === '!') continue;
          let tt = 0;
          for (let i=0; i<cell.length; i++) {
            if (!isNaN(cell[i])) {
              tt = i;
              break;
            }
          }
          // const col = cell.substring(0,tt);
          const row = parseInt(cell.substring(tt));
          if (row > maxRow) {
            maxRow = row;
          }
        }
        // The headers are on row 7.
        // The data starts on row 8, and continues to the 3rd-from-last row.
        const range = "A7:K" + (maxRow-2);
        // console.log('reading from range', range);
        worksheet['!ref'] = range;
        const json = XLSX.utils.sheet_to_json(worksheet);
        callbackWithJson(jsonConvertForDisplay(json));
      }
      reader.readAsArrayBuffer(file);
    },
    importJson: async (json) => {
      const payload = jsonConvertForSave(json);
      return await TenantsService.import(payload);
    },
  };
  return AbstractMaintain(props, {
    columnDefs,
    dataService: TenantsService,
    dataDescriptionStr: "Tenant",
    importerConfig
  });
};

export default MaintainTenants;
