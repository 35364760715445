import React from "react";

const Message = (props) => {
  const {message, isError} = props;
  if (!message) {
    return null;
  }
  const style = isError ? {color:"red"} : {color:"green"};
  return <div style={style}>{message}</div>;
};

export default Message;