import PWWorkOrderService from "../../../services/PWWorkOrderService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainWorkOrders = (props) => {
  const columnDefs = [
    {
      Header: "Id",
      accessor: "id",
      type: "text",
      isEditable: false,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Description",
      accessor: "description",
      type: "text",
      isEditable: false,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Category",
      accessor: "category",
      type: "text",
      isEditable: false,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Priority",
      accessor: "priority",
      type: "text",
      isEditable: false,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Status",
      accessor: "status",
      type: "text",
      isEditable: false,
      isRequired: true,
      isSearchable: true,
    }
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: PWWorkOrderService,
    dataDescriptionStr: "PropertyWare WorkOrders",
    allowDelete: false,
  });
}

export default MaintainWorkOrders;