import AbstractService from "./AbstractService";

class UsersService extends AbstractService {

  constructor() {
    super("users");
  }

  async login(login, password) {
    return await this._doPost("/login", {login,password});
  }

  async changePassword(userId, oldPassword, newPassword) {
    return await this._doPut("/changepassword/" + encodeURIComponent(userId), {oldPassword,newPassword});
  }

  async updateGeneralInfo(userId, login, firstName, lastName) {
    return await this._doPut("/update/general/" + encodeURIComponent(userId),
      {login,firstName,lastName});
  }
};

export default new UsersService();