import React from "react";
import ReactJson from '@microlink/react-json-view';

const RubsExpandingViewer = ({
                         value,
                       }) => {

  let _value;
  try {
    if (value) {
      _value = JSON.parse(value);
    } else {
      _value = {};
    }
  } catch (error) {
    console.error(error);
    console.error('value',value);
    _value = {"error":"there was an error parsing the value, check logs for details"};
  }
  return <ReactJson src={_value}
                    name={false}
                    collapsed={true}
  />;
};

export default RubsExpandingViewer;