import SmartMetersService from "../../../services/SmartMetersService";
import UtilityAccountsServiceClass from "../../../services/UtilityAccountsServiceClass";
import UnitsService from "../../../services/UnitsService";
import UOMTypeService from "../../../services/UOMTypeService";
import AbstractMaintain from "./AbstractMaintain";

const defaultResult = {data:[]};

// This uses the UtilityAccountsService but sends an extra parameter
// to indicate the call should only return smart-meter accounts.
class SmartMeterUtilityAccountsService extends UtilityAccountsServiceClass {
  async getWithRelations(id = null, params, includeCount = false) {
    const newParams = {...params};
    newParams.useVariation=1;
    return await super.getWithRelations(id, newParams, includeCount);
  }
}
const UtilityAccountsService = new SmartMeterUtilityAccountsService();

const MaintainSmartMeters = (props) => {
  const columnDefs = [
    {
      Header: "Account Identifier",
      accessor: "utility_account_id",
      type: "searchselect",
      lookupService: UtilityAccountsService,
      isEditable: true,
      isRequired: true,
      optionMapFunction: UtilityAccountsService.optionMapFunction,
      searchSelectionMatcher: UtilityAccountsService.searchSelectionMatcher,
      triggerLookupRefreshOnChange: true,
      forceValidSelectionOnLookup: true,
    },
    {
      Header: "Unit",
      accessor: "building_unit_id",
      type: "select",
      lookupFunction: async (data) => {
        if (!data || !data['utility_account_id']) {
          return defaultResult;
        }
        const utilityAccountId = data['utility_account_id']['id'] || data['utility_account_id'];
        if (isNaN(utilityAccountId)) {
          return defaultResult;
        }
        const result = await UnitsService.forAccount(utilityAccountId);
        return result;
      },
      forceValidSelectionOnLookup: true,
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Account Detail",
      accessor: "derived_account_detail",
      type: "derived",
      isEditable: false,
      isSearchable: true,
      isRequired: false,
    },
    {
      Header: "Measured Date",
      accessor: "measured_date",
      type: "yyyymmdd",
      isEditable: true,
    },
    {
      Header: "Amount",
      accessor: "measured_amount",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "UOM Type",
      accessor: "uom_type_id",
      type: "select",
      lookupService: UOMTypeService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
    },
    {
      Header: "Meter Id",
      accessor: "meter_id",
      type: "text",
      isEditable: true,
      isRequired: false,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: SmartMetersService,
    dataDescriptionStr: "Smart Meters",
  });
};

export default MaintainSmartMeters;