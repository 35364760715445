import AbstractService from "./AbstractService";

class PortfoliosService extends AbstractService {

  constructor() {
    super("portfolios");
  }

}

export default new PortfoliosService();