const leaseTypes = [
  {id:1,name:"Student"},
  {id:2,name:"Residential"},
  {id:3,name:"Commercial"},
];

class LeaseTypeService {

  async get(id = null) {
    if (id) {
      return {data:leaseTypes[id-1]};
    } else {
      return {data:leaseTypes};
    }
  }

}

export default new LeaseTypeService();