class InputValidator {

  /*
   * Confirms that the value is valid for this column.
   * If invalid, will throw an exception with the validation message.
   * This function also handles default values, and so will return
   * the value of the field if a default is applied.
   */
  static validate(columnDef, obj, keyToValidate) {
    // console.log('InputValidator.validate columnDef', columnDef);
    // console.log('InputValidator.validate obj', obj);
    // console.log('InputValidator.validate keyToValidate', keyToValidate);
    let value = obj[keyToValidate]
    // apply default
    if (columnDef.type === 'toggle') {
      // this might work on the "else" condition as well, but didn't test
      if (columnDef.defaultValue && value === undefined) {
        value = columnDef.defaultValue;
      }
    } else {
      // NOTE: the `!value` check doesn't handle scenario where the column
      //       allows for `false` or `0`, etc., as valid values
      value = (columnDef.defaultValue && !value) ? columnDef.defaultValue : value;
    }
    // console.log('InputValidator.validate value', value);

    let isRequired = false;
    if (columnDef.isRequired) {
      if (typeof columnDef.isRequired === 'function') {
        isRequired = columnDef.isRequired(obj);
        // console.log('called function, isRequired result = ', isRequired);
      } else {
        isRequired = columnDef.isRequired;
      }
    }
    if (isRequired && !value) {
      throw 'required';
    }
    if (value && columnDef.validator) {
      // if invalid, this should throw an exception string with the validation error,
      // and that exception should be passed up the calling chain
      columnDef.validator(value);
    }
    // we may have modified this to be the default value, so let's return it for the caller to use
    return value;
  }

}

export default InputValidator;