import React from "react";
import RubsDatePicker from "../../UI/organisms/RubsDatePicker";
import RubsNumberInput from "../../UI/organisms/RubsNumberInput";
import Button from '@mui/material/Button';

const CalcChooseFilters = (params) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setElectricAdjustmentRate,
    handleSubmit,
  } = params;

  /*
   * dateOrig: YYYY-MM-DD formatted date with no timestamp
   * daysModification: number of days to add, or subtract if negative
   */
  const _addDays = (origDate, numberOfDays) => {
    const date = new Date(origDate + "T12:00:00Z")
    date.setDate(date.getDate() + numberOfDays);
    return date.toISOString().split('T')[0];
  };
  const _setStartDate = (date) => {
    setStartDate(date);
    if (date >= endDate) {
      setEndDate(_addDays(date,1));
    }
  };
  const _setEndDate = (date) => {
    setEndDate(date);
    if (date <= startDate) {
      setStartDate(_addDays(date,-1));
    }
  };

  return <div>
    <p>Select a date range to calculate tenant utilities:</p>
    <div>
        <span>
          <RubsDatePicker title="From"
                          selected={startDate}
                          onChange={_setStartDate}
                          fullWidth={false}
          />
        </span>
      <span style={{
        paddingLeft: "10px",
      }}>
          <RubsDatePicker title="To"
                          selected={endDate}
                          onChange={_setEndDate}
                          fullWidth={false}
          />
        </span>
    </div>
    <p>Select the electric adjustment rate:</p>
    <div>
      <RubsNumberInput initialValue={1}
                       onValueChange={setElectricAdjustmentRate}
                       min={0}
                       max={2}
      />
    </div>
    <div style={{
      paddingTop: "25px"
    }}>
      <Button onClick={handleSubmit}>Continue</Button>
    </div>
  </div>;
};

export default CalcChooseFilters;