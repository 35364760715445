import AbstractService from "./AbstractService";

class UnitsService extends AbstractService {

  constructor() {
    super("units");
  }

  async forBuilding(buildingId) {
    const relativeUrl = "/forBuilding/" + buildingId;
    return await super._doGet(relativeUrl);
  }

  async forAccount(accountId) {
    const relativeUrl = "/forAccount/" + accountId;
    return await super._doGet(relativeUrl);
  }
}

export default new UnitsService();