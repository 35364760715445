import AbstractService from "./AbstractService";

class BillsService extends AbstractService {
    
  constructor() {
    super("bills")
  }

  async getDefaultDates(utilityAccountId) {
    const relativeUrl = "/defaultdates/" + utilityAccountId;
    const result = await super._doGet(relativeUrl);
    if (result && result['data']) {
      return result['data'];
    }
    return null;
  }

}

export default new BillsService();