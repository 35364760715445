import React from "react";
import {useState} from '../../../utils/ReactUtils';
import CalcService from "../../../services/CalcService";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import {saveAs} from 'file-saver';

const RUBS_XLS_HEADER_ROW = [
  'Lease','Date','Amount','Account','Comments',
  'Payment Type','Payment Ref No','Payment Comments',
  'Payment Account','Portfolio\\Building\\Unit',
  'Tenant Status'
];
const BILLS_IMPORT_HEADER_ROW = [
  'Vendor','Portfolio','Building','Unit',
  'Date','Amount','Account','Split Comments',
  'Bill Description','Ref #','Payment Account','Payment Type',
  'Payment Check No','Payment Comments','Work Order #'
];

const hasData = arr => arr && arr.length > 0;

const convertBillsImportToSheetJSStream = (billsImport, startDate, endDate) => {
  const convertBillsImportRecord = (billsImportRecord) => {
    const {
      Vendor:vendor,
      Portfolio:portfolio,
      Building:building,
      Unit:unit,
      Date:date,
      Amount:amount,
      Account:account,
      "Split Comments":splitComments,
      "Bill Description":billDescription,
      "Ref #":refNo,
    } = billsImportRecord;
    const roundedAmount = Math.round((amount+Number.EPSILON)*100)/100;
    return [vendor,portfolio,building,unit,date,roundedAmount,account,splitComments
      ,billDescription
      ,refNo
      ,// Payment Account
      ,// Payment Type
      ,// Payment Check No
      ,// Payment Comments
      ,// Work Order #
    ];
  };
  const wbTitle = "RUBS Bills " + startDate + " to " + endDate;
  return convertToSheetJSStream(billsImport, convertBillsImportRecord, wbTitle, BILLS_IMPORT_HEADER_ROW);
};
const convertRUBSDataToSheetJSStream = (tenantTotalCostSummary, startDate, endDate,) => {
  let chargeDate = new Date();
  chargeDate.setHours(12);
  const [year,month,day] = chargeDate.toISOString().split('T')[0].split('-');
  chargeDate = month + "/" + day + "/" + year;
  const convertTenantRecord = (tenantRecord) => {
    const {leaseName,amount,pwAccount,comment,tenantStatus} = tenantRecord;
    const roundedAmount = Math.round((amount+Number.EPSILON)*100)/100;
    return [leaseName,chargeDate,roundedAmount,pwAccount,comment,,,,,,tenantStatus];
  };
  const wbTitle = "RUBS Charges " + startDate + " to " + endDate;
  return convertToSheetJSStream(tenantTotalCostSummary, convertTenantRecord, wbTitle, RUBS_XLS_HEADER_ROW);
};

const convertToSheetJSStream = (data, buildRowFn, wbTitle, headerRow) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: wbTitle,
  };
  wb.SheetNames.push("data");
  const rows = [];
  rows.push(headerRow);
  for (const datum of data) {
    rows.push(buildRowFn(datum));
  }
  const ws = XLSX.utils.aoa_to_sheet(rows);
  wb.Sheets["data"] = ws;
  return XLSX.write(wb, {bookType:'xlsx', type:'binary'});
};

const streamToArrayBuffer = (octetStream) => {
  const buffer = new ArrayBuffer(octetStream.length);
  const view = new Uint8Array(buffer);
  for (let i=0; i<octetStream.length; i++) {
    view[i] = octetStream.charCodeAt(i) & 0xFF;
  }
  return buffer;
};

const CalcGenXLS = (params) => {
  const {
    startDate,
    endDate,
    electricAdjustmentRate,
    reset,
    setErrorMessage,
  } = params;

  const [isLoading,setIsLoading] = useState(true,'loading');

  React.useEffect( () => {
    if (!isLoading) {
      return;
    }
    const doCalculationCall = async () => {
      const fileSuffix = "_" + startDate.replaceAll('-','')
        + "_" + endDate.replaceAll('-','') + ".xlsx";
      const saveStream = (stream, fileName) => {
        const buffer = streamToArrayBuffer(stream);
        saveAs(new Blob([buffer],{type:"application/octet-stream"}), fileName);
      }
      const saveRUBS = (xlsData, filename = "RUBS") => {
        if (hasData(xlsData)) {
          const stream = convertRUBSDataToSheetJSStream(xlsData, startDate, endDate);
          saveStream(stream,filename + fileSuffix);
        }
      }
      const saveBillsImport = (billsImport) => {
        if (hasData(billsImport)) {
          const stream = convertBillsImportToSheetJSStream(billsImport, startDate, endDate);
          saveStream(stream,"RUBS_Bills_Import" + fileSuffix);
        }
      }
      setIsLoading(true);
      const _data = (await CalcService.doCalculations({startDate,endDate,electricAdjustmentRate,dataset:'generateXLS'}))['data'];
      if (_data['error']) {
        setErrorMessage(_data['error'])
      } else {
        const xlsData = _data['generateXLS'];
        const xlsData_inactiveTenants = _data['generateXLS_inactiveTenants'];
        const billsImport = _data['billsImport'];
        // filter out invalids
        const _filter = el => {
          return el.leaseName && el.amount && el.amount > 0;
        };
        const filteredData = xlsData.filter(_filter);
        saveRUBS(filteredData, "RUBS");
        const filteredInactiveTenants = xlsData_inactiveTenants.filter(_filter);
        saveRUBS(filteredInactiveTenants, "RUBS_InactiveTenants");
        saveBillsImport(billsImport);
      }
      setIsLoading(false);
    };
    doCalculationCall().catch(error => {
      setIsLoading(false);
      console.error(error);
      setErrorMessage(error.message ? error.message : "Unknown error; Check logs for more details.");
    });
  },[isLoading]);

  if (isLoading) {
    return <div>Loading...</div>
  }
  return <div>
    <div style={{display:"flex"}}>
      <Button onClick={reset}>Reset</Button>
    </div>
    <span>Your PropertyWare export has been generated.</span>
  </div>;
};

export default CalcGenXLS;