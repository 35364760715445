const utilityTypes = [
  {id:1,name:"Electric"},
  {id:2,name:"Gas"},
  {id:3,name:"Water"}
];

class UtilityTypeService {

  async get(id = null) {
    if (id) {
      return {data:utilityTypes[id-1]};
    } else {
      return {data:utilityTypes};
    }
  }

}

export default new UtilityTypeService();