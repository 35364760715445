const processStatusTypes = [
  {id:1,name:"Not Started"},
  {id:2,name:"In Process"},
  {id:3,name:"Success"},
  {id:4,name:"Error"},
];

class ProcessStatusTypeService {

  async get(id = null) {
    if (id) {
      return {data:processStatusTypes[id-1]};
    } else {
      return {data:processStatusTypes};
    }
  }

}

export default new ProcessStatusTypeService();