import AbstractService from "./AbstractService";

class TenantsService extends AbstractService {

  constructor() {
    super("tenants");
  }

  async import(jsonArray) {
    return await super._doPost("/import", jsonArray);
  }
}

export default new TenantsService();