import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import NamingThings from "../../../utils/NamingThings";

const RubsSelect = (params) => {

  const {
    selectOptions,
    title,
    index,
    errorMessage,
    readOnly,
    // [value, setValue] = React.useState() in caller
    value,
    setValue,
    optionFilterColumn,
  } = params;

  const [inputValue, setInputValue] = React.useState('');

  const hasError = !!errorMessage;
  const filterColumn = optionFilterColumn || 'name';
  const options = selectOptions.map(obj => obj[filterColumn]);
  const selectId = NamingThings.buildKey("select", title, index);

  const textField = (params) =>
    <TextField
      {...params}
      label={title}
      error={hasError}
      helperText={hasError ? errorMessage : null}
      InputLabelProps={{
        shrink: true,
        sx: {
          marginTop: 0,
        }
      }}
    />;
  const selectSection = <Autocomplete
    id={selectId}
    options={options}
    disabled={readOnly}
    value={(value) ? value.name : null}
    onChange={(event,newValue) => {
      // the 'newValue' is just the name,
      // but `value` is an object like {id:1,name:'abc'}
      // so we need to find object match from `selectOptions`
      setValue(selectOptions.find(element => element.name === newValue));
    }}
    inputValue={inputValue}
    onInputChange={(event,newInputValue) =>
      setInputValue(newInputValue)
    }
    renderInput={textField}
    disablePortal
    disableClearable
    autoHighlight
    autoSelect
  />;

  return <FormControl
    fullWidth
    variant="filled"
    key={NamingThings.buildKey("select_key", title, index)}
  >
    {selectSection}
  </FormControl>;
};

export default RubsSelect;