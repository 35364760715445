import {Link} from "react-router-dom";
import React, {useState, useEffect} from "react";

function GlobalState(activePage) {
    try {
      const environment = process.env.REACT_APP_ENVIRONMENT;
      const prefix = (environment === 'PROD') ? '' : '(' + environment + ') ';
      let title = prefix + "Resident Utility Billing System";
      if (activePage[0].title !== "Home") {
        title += " - " + activePage[0].title;
      }

      document.title = title;
      const header = document.getElementById("mainHeader");
      if (header) {
        header.innerHTML = title;
      }
    } catch(err) {
      console.error(err);
    }
}

function Update(newHeader) {
  const [activePage, setActivePage] = useState([]);
  useEffect(() => {
    setActivePage(x => {
      return [{title: newHeader}];
    });
  }, []);
  return [activePage, setActivePage];
}

function MenuItem({path,text,header}) {
  const newHeader = header ? header : text;
  const [asdf, setAsdf] = Update(newHeader);

  async function ChangeHeaderOnClick() {
    GlobalState(asdf);
  }

  return <li style={{display:"inline"}}><Link
    to={path}
    onClick={ChangeHeaderOnClick}
  >{text}</Link></li>
}

export default MenuItem;