import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useAuth} from "../../utils/useAuth";
import {useState} from "../../utils/ReactUtils";
import {useNavigate} from "react-router-dom";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null,"anchorEl");
  const {user,logout} = useAuth();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  const isOpen = Boolean(anchorEl);
  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const closeMenu = () => setAnchorEl(null);

  const navigateToProfile = () => {
    closeMenu();
    navigate('/profile');
  };
  const doLogout = () => {
    closeMenu();
    logout();
  }

  const navigateToMaintainUsers = () => {
    closeMenu();
    navigate('/administerusers');
  }

  let administerUsers = null;
  if (user['is_admin']) {
    administerUsers = <MenuItem key="settingsMenuAdministerUsers" onClick={navigateToMaintainUsers}>Users</MenuItem>;
  }

  const menuTitle = user['firstName'] + ' ' + user['lastName'];
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={toggleMenu}
      >
        {menuTitle}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem key="settingsMenuProfile" onClick={navigateToProfile} text="Profile">Profile</MenuItem>
        <MenuItem key="settingsMenuLogout" onClick={doLogout}>Logout</MenuItem>
        {administerUsers}
      </Menu>
    </div>
  );
};

export default ProfileMenu;