import AbstractBaseService from "./AbstractBaseService";

// TODO provide authentication on these calls

class AbstractService extends AbstractBaseService {

  async get(id = null) {
    const relativeUrl = (id) ? "/" + id : "";
    return await this._doGet(relativeUrl);
  }

  async delete(id) {
    if (!id) {
      throw new Error("tried to delete by id: " + id);
    }
    const relativeUrl = "/" + id;
    return await this._doDelete(relativeUrl);
  }

  async getWithRelations(id = null, params, includeCount = false) {
    let relativeUrl = "/with/relations" + ((id) ? "/" + id : "");
    if (includeCount) {
      relativeUrl += "/and/count";
    }
    if (params) {
      let first = true;
      for (const [key,value] of Object.entries(params)) {
        if (first) {
          relativeUrl += "?";
          first = false;
        } else {
          relativeUrl += "&";
        }
        relativeUrl += key + "=" + encodeURIComponent(value);
      }
    }
    return await this._doGet(relativeUrl);
  }

  async save(id, valuesJson) {
    return await this._doPut("/" + id, valuesJson);
  }

  async create(valuesJson) {
    return await this._doPost("", valuesJson);
  }

  async lazyload(id, columnName) {
    const relativeUrl = "/lazyload/" + id + "/" + encodeURIComponent(columnName);
    return await this._doGet(relativeUrl);
  }
}

export default AbstractService;