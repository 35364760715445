import React from 'react';

function NavMenu(props) {
  return (
    <nav>
      <ul style={{marginTop:"4px",marginBottom:"4px"}}>
        {props.children}
      </ul>
    </nav>
  );
}

export default NavMenu;