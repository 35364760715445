import AbstractService from "./AbstractService";

class ProvidersService extends AbstractService {

  constructor() {
    super("providers");
  }

}

export default new ProvidersService();