import React from "react";
import RawImportsService from "../../../services/RawImportsService";
import AbstractMaintain from "./AbstractMaintain";
import ProcessStatusTypeService from "../../../services/ProcessStatusTypeService";
import RubsExpandingViewer from "../../UI/organisms/RubsExpandingViewer";

const MaintainRawImports = (props) => {
  const columnDefs = [
    {
      Header: "Status",
      accessor: "process_status_type_id",
      type: "select",
      lookupService: ProcessStatusTypeService,
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Source",
      accessor: "source",
      type: "text",
      isEditable: false,
      isSearchable: true,
    },
    {
      Header: "File",
      accessor: "filename",
      type: "text",
      isEditable: false,
      isSearchable: true,
    },
    {
      Header: "Created Date",
      accessor: "created_on",
      type: "text",
      isEditable: false,
      isSearchable: true,
    },
    {
      Header: "Result",
      accessor: "result_msg",
      type: "text",
      isEditable: false,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "Data",
      accessor: "raw_data",
      type: "text",
      isEditable: false,
      lazyload: true,
      renderCell: (value) => {
        if (!value) {
          return value;
        }
        return <RubsExpandingViewer value={value} />;
      },
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: RawImportsService,
    dataDescriptionStr: "Imports",
  });
};

export default MaintainRawImports;
