import BuildingsService from "../../../services/BuildingsService";
import PortfoliosService from "../../../services/PortfoliosService";
import ClientsService from "../../../services/ClientsService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainBuildings = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Alternate Names",
      accessor: "alternate_names",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "PropertyWare Name",
      accessor: "propertyware_name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header:"Address",
      accessor:"street_address",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header:"City",
      accessor:"city",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header:"State",
      accessor:"state",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header:"Zip Code",
      accessor:"zip",
      type:"text",
      isEditable: true,
      isSearchable: true,
    },
    {
      Header: "Client",
      accessor: "client_id",
      type: "select",
      lookupService: ClientsService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: true,
    },
    {
      Header: "Portfolio",
      accessor: "portfolio_id",
      type: "select",
      lookupService: PortfoliosService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: true,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: BuildingsService,
    dataDescriptionStr: "Building",
  });
};

export default MaintainBuildings;
