import AbstractService from "./AbstractService";

class PWWorkOrderService extends AbstractService {

  constructor() {
    super("pwworkorder");
  }

}

export default new PWWorkOrderService();