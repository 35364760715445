class SmartSelectRequest {
  constructor(config,selectedRow=null) {
    const tiers = [];
    for (let i=0; i<config.tiers.length; i++) {
      const type = config.tiers[i];
      tiers.push({type});
    }
    this._tiers = tiers;
    this._finalSelectionId = (selectedRow) ? selectedRow.id : null;
  }

  setTierSelection(tierName,selectedId) {
    for (let i=0; i<this._tiers.length; i++) {
      const tier = this._tiers[i];
      if (tier['type'] == tierName) {
        if (selectedId) {
          tier.selected = selectedId;
        } else {
          delete tier.selected;
        }
        break;
      }
    }
  }

  toJson() {
    const json = {};
    json['tiers'] = this._tiers;
    if (this._finalSelectionId) {
      json['finalSelection'] = this._finalSelectionId;
    }
    return json;
  }
}

export default SmartSelectRequest;