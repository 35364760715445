import React from "react";
import TextField from "@mui/material/TextField";
import NamingThings from "../../../utils/NamingThings";

const defaultInputProps = {};
const readOnlyInputProps = {readOnly: true};

const RubsTextField = ({
                         title,
                         index,
                         errorMessage,
                         readOnly,
                         value,
                         setValue,
                         fullWidth=true,
                         variant,
                         type,
                         style={},
                         onKeyDown,
                         autoFocus,
                       }) => {

  const hasError = !!errorMessage;

  return <TextField
    id={NamingThings.buildKey("input",title,index)}
    key={NamingThings.buildKey("input_key",title,index)}
    label={title}
    fullWidth={fullWidth}
    variant={variant}
    disabled={readOnly}
    error={hasError}
    helperText={hasError ? errorMessage : null}
    style={style}
    type={type}
    autoFocus={autoFocus}
    InputLabelProps={{
      shrink: true,
      sx: {
        marginTop: 0,
        minWidth: "50%",
      }
    }}
    value={value}
    onChange={setValue}
    onKeyDown={onKeyDown}
    InputProps={readOnly ? readOnlyInputProps : defaultInputProps}
  />;
};

export default RubsTextField;