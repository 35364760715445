//sourced from https://medium.com/@subalerts/create-dynamic-table-from-json-in-react-js-1a4a7b1146ef
import React, {useEffect, useState} from "react";
export default class Table extends React.Component {
    constructor(props){
    super(props);
    this.getHeader = this.getHeader.bind(this);
    this.getRowsData = this.getRowsData.bind(this);
    this.getKeys = this.getKeys.bind(this);
    }
    getKeys = function(){
        return Object.keys(this.props.data[0]);
    }
    getHeader = function(){
        let keys = this.getKeys();
        return keys.map((key,index)=>{
            return <th key={key}>{key.toUpperCase()}</th>
        })
    }
    getRowsData = function(){
        let items = this.props.data;
        let keys = this.getKeys();
        return items.map((row,index)=>{
            return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
        })
    }

    render() {
        const noData = <div>There is no data to display.</div>;
        if (this.props.data) {
            if (!Array.isArray(this.props.data)) {
                console.log(this.props.data);
                return <div>ERROR: Expected an array, but it was something else. Check console log.</div>;
            } else if (this.props.data.length == 0) {
                return noData;
            } // else it is valid for display
        } else {
            return noData;
        }
        return (
            <div>
                <table>
                    <thead>
                        <tr>{this.getHeader()}</tr>
                    </thead>
                    <tbody>
                        {this.getRowsData()}
                    </tbody>
                </table>
            </div>
        );
    }
}
const RenderRow = (props) =>{
    return props.keys.map((key,index)=>{
        return <td key={props.data[key]}>{props.data[key]}</td>
    })
}