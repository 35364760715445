import AbstractBaseService from "./AbstractBaseService";

class CalcService extends AbstractBaseService {

  constructor() {
    super("calculations");
  }

  async doCalculations(request) {
    const result = await this._doPost("/calculations", request);
    return result;
  }

}

export default new CalcService();