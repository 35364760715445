import React, { useEffect, useState }  from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import NamingThings from "../../../utils/NamingThings";

const RubsSearchSelect = (params) => {
  const [searchQuery,setSearchQuery] = useState('');
  const [lookupResults,setLookupResults] = useState([]);
  const [displayValue, setDisplayValue] = useState(null);

  const {
    dataService,
    title,
    errorMessage,
    index,
    searchSelectionMatcher,
    optionMapFunction,
    onSelected,
    preloadId,
  } = params;

  const doPreload = async (id) => {
    const result = (await dataService.getWithRelations(id))['data'];
    const description = optionMapFunction(result);
    setDisplayValue(description);
  };

  useEffect( () => {
    if (!(preloadId)) {
      return;
    }
    doPreload(preloadId).catch(console.error);
  },[preloadId]);

  const doSearch = async () => {
    const filters = {
      offset: 0,
      limit: 10,
      qq: encodeURIComponent(searchQuery),
    };
    const result = await dataService.getWithRelations(null,filters);
    setLookupResults(result.data);
  };

  useEffect( () => {
    if (searchQuery.length === 0) {
      setLookupResults([]);
      return;
    }
    doSearch().catch(console.error);
  }, [searchQuery]);

  const hasError = !!errorMessage;
  const selectId = NamingThings.buildKey("select", title, index);
  const options = lookupResults.map(optionMapFunction);

  const textField = (params) =>
    <TextField
      {...params}
      label={title}
      error={hasError}
      helperText={hasError ? errorMessage : null}
      InputLabelProps={{
        shrink: true,
        sx: {
          marginTop: 0,
        }
      }}
    />;

  const valueToDisplay =
    searchQuery.length === 0 ?
      displayValue ? displayValue : ''
      : searchQuery;
  const selectSection = <Autocomplete
    id={selectId}
    options={options}
    value={(displayValue) ? displayValue : null}
    onChange={(event,newDisplayValue) => {
      setDisplayValue(newDisplayValue);
      const match = lookupResults.find(obj => {
        return searchSelectionMatcher(obj, newDisplayValue);
      });
      if (onSelected && typeof onSelected === 'function') {
        onSelected(match.id);
      }

    }}
    inputValue={valueToDisplay}
    onInputChange={(event,newInputValue,type) => {
      if (type === 'reset') {
        setSearchQuery('');
      } else {
        setSearchQuery(newInputValue)
      }
    }}
    renderInput={textField}
    disablePortal
    disableClearable
    autoHighlight
    autoSelect
    filterOptions={(x) => x}
    isOptionEqualToValue={()=>true}
  />;

  return <FormControl fullWidth
                      variant="filled"
                      key={NamingThings.buildKey("select_key", title, index)}
  >
    {selectSection}
  </FormControl>;
};

export default RubsSearchSelect;