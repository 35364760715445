class DefaultReportingDateRange {
  static #isWednesday(day){
    return day === 3;
  }

  /*
   * Generates two dates from the current date
   * The 1st date is the previous Wednesday from today
   * The 2nd date is the previous Thursday to the 1st date
   */
  static calculate(date = new Date()) {
    let day = date.getDay();
    let offset = 0;

    //If we are currently on a Wednesday, we need to ultimately point to the previous Wednesday still. We do this by fudging the day to Tuesday and introducing an offset
    if (this.#isWednesday(day)) {
      day = day - 1;
      offset = -1;
    }

    const previousWednesday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - (day + 4) % 7 + offset);
    const previousThursday = new Date(previousWednesday.getFullYear(), previousWednesday.getMonth(), previousWednesday.getDate() - 6);

    return [previousWednesday.toISOString().split('T')[0], previousThursday.toISOString().split('T')[0]];
  }
}

export default DefaultReportingDateRange;