import AbstractService from "./AbstractService";

class SmartMetersService extends AbstractService {

  constructor() {
    super("smartmeters");
  }

}

export default new SmartMetersService();