import React from "react";

const useState = (defaultValue, name, doLogUsage=false) => {
  const [value, setter] = React.useState(defaultValue);
  React.useEffect(()=>{
    if (doLogUsage) {
      console.log(name + ' changed: ',value);
    }
  },[value]);
  return [value,setter];
};

const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

// TODO these constants and functions probably don't belong here:
const ENTER = 13;
const ESCAPE = 27;
const _onKeyDown = (event,func,keyCode) => {
  if (event.keyCode === keyCode) {
    func(event);
  }
};
const onEscape = (event,func) => {
  _onKeyDown(event,func,ESCAPE);
};
const onEnter = (event,func) => {
  _onKeyDown(event,func,ENTER);
};

export {useState,useLocalStorage,onEscape,onEnter};