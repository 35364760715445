import AbstractBaseService from "./AbstractBaseService";

class SmartSelectService extends AbstractBaseService {

  constructor() {
    super("utility/smartselect");
  }

  async lookup(smartSelectRequest) {
    const result = await this._doPost("/lookup", smartSelectRequest.toJson());
    return result;
  }
}

export default new SmartSelectService();