import React from "react";
import GeneralSettings from "./GeneralSettings";
import ChangePassword from "./ChangePassword";
import {useAuth} from "../../../utils/useAuth";


const AdministerSettings = (props) => {
  const {user} = useAuth();
  const userId = user.id;

  return (
    <div>
      <h3>Profile</h3>
      <div style={{display:"flex"}}>
        <span style={{flex: "25%"}}><GeneralSettings userId={userId} /></span>
        <span style={{flex: "25%"}}><ChangePassword userId={userId} /></span>
        <span style={{flex: "50%"}} />
      </div>
    </div>
  );

  //         {/*<FormControlLabel control={*/}
  //         {/*  <Checkbox checked={isAdmin}*/}
  //         {/*            onChange={e=>setIsAdmin(!isAdmin)}*/}
  //         {/*  />} label="Is Admin?" />*/}
}

export default AdministerSettings;