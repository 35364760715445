import AbstractService from "./AbstractService";

class ClientsService extends AbstractService {

  constructor() {
    super("clients");
  }

}

export default new ClientsService();