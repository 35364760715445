import PWAccountRefService from "../../../services/PWAccountRefService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainPWAccounts = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Code",
      accessor: "code",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    }
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: PWAccountRefService,
    dataDescriptionStr: "PropertyWare Accounts",
  });
}

export default MaintainPWAccounts;