import LeaseTypeService from "../../../services/LeaseTypeService";
import UnitsService from "../../../services/UnitsService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainUnits = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Building",
      accessor: "building_id",
      type: "smartselect",
      smartSelectConfig: {
        tiers: ["client","building"],
      },
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Alternate Names",
      accessor: "alternate_names",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "PropertyWare Name",
      accessor: "propertyware_name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Lease Type",
      accessor: "lease_type_id",
      type: "select",
      lookupService: LeaseTypeService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: false,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: UnitsService,
    dataDescriptionStr: "Unit",
  });
}

export default MaintainUnits;