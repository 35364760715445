import BillTargetTypeService from "../../../services/BillTargetTypeService";
import BuildingsService from "../../../services/BuildingsService";
import UnitsService from "../../../services/UnitsService";
import ProvidersService from "../../../services/ProvidersService";
import UtilityTypeService from "../../../services/UtilityTypeService";
import BillTypeService from "../../../services/BillTypeService";
import UtilityAccountsService from "../../../services/UtilityAccountsService";
import PWAccountRefService from "../../../services/PWAccountRefService";
import AbstractMaintain from "./AbstractMaintain";

const defaultResult = {data:[]};

const MaintainUtilityAccounts = (props) => {
  const columnDefs = [
    {
      Header: "Target",
      accessor: "bill_target_type_id",
      type: "select",
      lookupService: BillTargetTypeService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      triggerLookupRefreshOnChange: true,
      onChangeAffects: ["building_unit_id"],
      isSearchable: true,
    },
    {
      Header: "Building",
      accessor: "building_id",
      type: "select",
      lookupService: BuildingsService,
      isEditable: true,
      isRequired: (data) => data.bill_target_type_id == null || data.bill_target_type_id.id === 1,
      triggerLookupRefreshOnChange: true,
      defaultValue: 1,
      // isVisible: (data) => data.bill_target_type_id == null || data.bill_target_type_id === 1,
      onChangeAffects: ["building_unit_id"],
      isSearchable: true,
    },
    {
      Header: "Unit",
      accessor: "building_unit_id",
      type: "select",
      lookupFunction: async (data) => {
        // console.log('building_unit_id lookup for data', data);
        if (!data || data.bill_target_type_id === 1) {
          // console.log('building_unit_id lookup - defaultResult');
          return defaultResult;
        }
        if (data.building_id) {
          // console.log('building_unit_id lookup - forBuilding ', data.building_id.id);
          const result = await UnitsService.forBuilding(data.building_id.id);
          // console.log('and the result is', result);
          return result;
        } else if (data.building_id == null) {
          // console.log('building_unit_id lookup - for default building');
          return await UnitsService.forBuilding(1);
        } else {
          return defaultResult;
        }
      },
      lookupService: UnitsService,
      isEditable: (data) => data.bill_target_type_id && (data.bill_target_type_id === 2 || data.bill_target_type_id.id === 2),
      isRequired: (data) => data.bill_target_type_id && (data.bill_target_type_id === 2 || data.bill_target_type_id.id === 2),
      // isVisible: (data) => data.bill_target_type_id && data.bill_target_type_id.id === 2,
      applyValueOverride: (data, value) => {
        // console.log('applyValueOverride data', data);
        // console.log('applyValueOverride value', value);
        if (data.bill_target_type_id && data.bill_target_type_id.id !== 2) {
          // console.log('result is null');
          return null;
        } else {
          // console.log('result is value: ',value);
          return value;
        }
      },
      isSearchable: true,
    },
    {
      Header: "Provider",
      accessor: "utility_provider_id",
      type: "select",
      lookupService: ProvidersService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: true,
    },
    {
      Header: "Account ID",
      accessor: "account_id",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Active",
      accessor: "active_status",
      type: "toggle",
      isEditable: true,
      isSearchable: false,
    },
    {
      Header: "Meter Number",
      accessor: "meter_number",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "Description",
      accessor: "description",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "Utility Type",
      accessor: "utility_type_id",
      type: "select",
      lookupService: UtilityTypeService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: true,
    },
    {
      Header: "Bill Type",
      accessor: "bill_type_id",
      type: "select",
      lookupService: BillTypeService,
      isEditable: true,
      isRequired: true,
      defaultValue: 1,
      isSearchable: true,
    },
    {
      Header: "PropertyWare Account",
      accessor: "pw_account_ref_id",
      type: "select",
      lookupService: PWAccountRefService,
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: UtilityAccountsService,
    dataDescriptionStr: "Utility Account",
  });
};

export default MaintainUtilityAccounts;
