import _axios from "axios";

const axios = _axios.create({headers:{common:{}}});
axios.interceptors.request.use(config => {
  let user = window.localStorage.getItem('user');
  if (!!user && user !== 'null') {
    user = JSON.parse(user);
    config.headers.common['Authorization'] = 'Bearer ' + user['session_uuid'];
    config.headers.common['user-id'] = user.id;
  }
  return config;
});
axios.interceptors.response.use(async res => {
  // console.log('received axios response:', res);
  return res;
  }, err => {
  // console.log('received axios error:',err);
  const failIf = (statusCode, message) => {
    if (err?.response?.status === statusCode
        && err?.response?.data?.message === message) {
      console.log('forcing logout: ' + message);
      window.localStorage.removeItem('user');
      window.location.href = '/login';
    }
  }
  failIf(401, 'session is expired');
  failIf(403, 'this account has been deactivated');
  failIf(401, 'session uuid is not valid for the user');
  throw err;
});

class AbstractBaseService {
  constructor(    // default
    serviceName = "UNKNOWN",
    url         = process.env.REACT_APP_SERVER_URL,
    port        = process.env.REACT_APP_SERVER_PORT,
    protocol    = process.env.REACT_APP_SERVER_PROTOCOL) {
    // this._serviceName = serviceName;
    let serviceUrl = protocol + "://" + url;
    if (port !== 80) {
      serviceUrl += ":";
      serviceUrl += port;
    }
    this._serviceUrl = serviceUrl + "/api/" + serviceName;
  }

  async _doGet(relativeUrl) {
    let requestUrl = this._serviceUrl + relativeUrl;
    // console.log('GET', requestUrl);
    try {
      const response = await axios.get(requestUrl);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async _doPut(relativeUrl, valuesJson) {
    let requestUrl = this._serviceUrl + relativeUrl;
    // console.log('PUT', requestUrl);
    try {
      const response = await axios.put(requestUrl, valuesJson);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async _doPost(relativeUrl, valuesJson) {
    let requestUrl = this._serviceUrl + relativeUrl;
    // console.log('POST', requestUrl);
    try {
      const response = await axios.post(requestUrl, valuesJson);
      // console.log('POST response:', response);
      return response;
    } catch (error) {
      console.log('post error:',error);
      throw error;
    }
  }

  async _doDelete(relativeUrl) {
    let requestUrl = this._serviceUrl + relativeUrl;
    // console.log('DELETE', requestUrl);
    try {
      const response = await axios.delete(requestUrl);
      // console.log('DELETE response: ', response);
      return response;
    } catch (error) {
      console.log('DELETE error: ', error);
      throw error;
    }
  }
}

export default AbstractBaseService;