import ClientsService from "../../../services/ClientsService";
import AbstractMaintain from "./AbstractMaintain";
const MaintainClients = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Contact Email",
      accessor: "contact_email",
      type: "text",
      isEditable: true,
      isSearchable: true,
    }
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: ClientsService,
    dataDescriptionStr: "Client",
  });
};

export default MaintainClients;