import React from "react";
import Table from '../maintenance/TableBuilder';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const CalcReview = (params) => {
  const {
    dataResult,
    activeStep,
    setActiveStep,
    reset,
  } = params;

  if (!dataResult) {
    return <div>Loading...</div>;
  }

  const handleChooseDetail = (event) => {
    setActiveStep(event.target.value);
  };
  const generateXLS = () => {
    setActiveStep("generateXLS");
  };

  const selectValue = activeStep ? activeStep : "periodUtilityDistribution";

  return <div>
    <div style={{display:"flex"}}>
      <Button onClick={reset}>Reset</Button>
      <Button style={{marginLeft:"20px"}}
              onClick={generateXLS}
      >Generate XLS</Button>
    </div>
    Review the calculated charges:
      <span>
      <Select value={selectValue}
              onChange={handleChooseDetail}
      >
        <MenuItem value="periodUtilityDistribution">Period Utility Distribution</MenuItem>
        <MenuItem value="totalCostSummary">Total Cost Summary</MenuItem>
        <MenuItem value="tenantUtilityCostSummary">Tenant Utility Cost Summary</MenuItem>
        {/*SAMI-108 <MenuItem value="feeProration">Fee Proration</MenuItem>*/}
        <MenuItem value="dailyUtilityDistributionRegistry">Daily Utility Distribution Registry</MenuItem>
        <MenuItem value="dailyTenantRegistry">Daily Tenant Registry</MenuItem>
        <MenuItem value="dailyCostPerUnitPerBill">Daily Cost Per Unit Per Bill</MenuItem>
        <MenuItem value="relevantBills">Relevant Utility Bills</MenuItem>
        <MenuItem value="relevantMeters">Relevant Meter Readings</MenuItem>
        {/*SAMI-108 <MenuItem value="relevantFees">Relevant Fees</MenuItem>*/}
        <MenuItem value="billsImport">Bills Import</MenuItem>
        <MenuItem value="finalBills">Final Bills</MenuItem>
        <MenuItem value="finalBillsTenants">Final Bills Tenants</MenuItem>
        <MenuItem value="finalBillsMostRecentBills">Final Bills Most Recent Bills</MenuItem>
        <MenuItem value="finalBillsDailyRegistry">Final Bills Daily Registry</MenuItem>
      </Select>
    </span>
    <Table data={dataResult} />
  </div>;
};

export default CalcReview;