import AbstractService from "./AbstractService";

class UtilityAccountsServiceClass extends AbstractService {

  constructor() {
    super("utilityaccounts");
  }

  optionMapFunction(utilityAccountObj) {
    let result =
      utilityAccountObj['account_id'] + ' - '
      + utilityAccountObj['utility_provider_id']['name']
      + ' (' + utilityAccountObj['utility_type_id']['name'] + '), '
      + utilityAccountObj['building_id']['name'];
    if (utilityAccountObj['building_unit_id'] && utilityAccountObj['building_unit_id']['name']) {
      result += ' Apt ' + utilityAccountObj['building_unit_id']['name'];
    }
    return result;
  }

  searchSelectionMatcher(datum, searchSelection) {
    const parts = searchSelection.split(' - ');
    let accountId = parts[0];
    let i = 1;
    let keepGoing = true;
    let provider = '';
    while (keepGoing) {
      let temp = parts[i];
      if (temp.includes(" (")) {
        temp = temp.split(' (')[0];
        keepGoing = false;
      } else {
        accountId += " - " + parts[i];
        i++;
        if (i >= parts.length) {
          keepGoing = false;
        }
      }
      if (!keepGoing) {
        provider = temp;
      }
    }
    const isMatch = datum['account_id'] === accountId
      && datum['utility_provider_id']['name'] === provider;
    return isMatch;
  }
}

export default UtilityAccountsServiceClass;