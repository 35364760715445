import React from "react";
import {useState} from "../../../utils/ReactUtils";
import NotificationsService from "../../../services/NotificationsService";

const CalcInfo = (params) => {
  const {
    x
  } = params;

  const [isLoading,setIsLoading] = useState(true,'loading');
  const [stagnantAccounts,setStagnantAccounts] = useState([],'stagnantAccounts');

  React.useEffect( () => {
    if (!isLoading) {
      return;
    }
    const getStagnantAccountsCall = async () => {
      setIsLoading(true);
      const _data = (await NotificationsService.getStagnantAccounts())['data'];
      setStagnantAccounts(_data);
      setIsLoading(false);
    };
    getStagnantAccountsCall().catch(error => {
      console.error(error);
    });
  }, [isLoading]);

  return isLoading ? null : (
    <div>
      <h2>Stagnant Utility Accounts</h2>
      <p>We do not have any recent bills (within the last 60 days)
        for the following utility accounts. Should these
        accounts be marked inactive?</p>
      <ul>
        {stagnantAccounts.map((item, index) => (
          <li key={index}>
            <b>{item.utilityAccount}</b>&nbsp;&nbsp;-&nbsp;&nbsp;{item.utilityProvider}
            &nbsp;&nbsp;&nbsp;&nbsp;(most recent bill date '<b>{item.billDate}</b>', for <b>{item.buildingPropertyWareName} {item.buildingUnitPropertyWareName}</b>)
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CalcInfo;