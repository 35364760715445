const uomTypes = [
  {id:1,name:"Gallons"},
  {id:2,name:"Kilowatt Hours"},
];

class UOMTypeService {

  async get(id = null) {
    if (id) {
      return {data:uomTypes[id-1]};
    } else {
      return {data:uomTypes};
    }
  }
}

export default new UOMTypeService();