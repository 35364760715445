import React from "react";
import {FormControlLabel,Switch} from "@mui/material";

const RubsToggle = ({
  label,
  checked,
  handleChange,
                    }) => {

  const toggle = <Switch checked={checked}
                         onChange={handleChange}
  />;

  return <FormControlLabel control={toggle} label={label} />;
}

export default RubsToggle;