// get data from the api:
//   - a unit id is already selected
//   - or this is a create, in which case it is not

// display a "disabled/readonly" text box
// on click, load the data/selections from smartselect api
// and generate select boxes from the payload

import React from "react";
import TextField from "@mui/material/TextField";
import SmartSelectService from "../../../services/SmartSelectService";
import SmartSelectRequest from "../../../services/model/SmartSelectRequest";
import NamingThings from "../../../utils/NamingThings";
import RubsSelect from "./RubsSelect";

const _useState = (defaultValue,name,doLogUsage=false) => {
  const [value, setter] = React.useState(defaultValue);
  React.useEffect(()=>{
    if (doLogUsage) {
      console.log(name + ' changed: ',value);
    }
  },[value]);
  return [value, setter];
};

const RubsSmartSelect = (params) => {
  const {
    title,
    config,
    index,
    errorMessage,
    value,
    setValue,
    selectParams,
  } = params;

  const [data, setData] = _useState(null,'data');
  const [isDataLoading, setIsDataLoading] = _useState(true,'isDataLoading');
  const [reloadData, _setReloadData] = _useState(0,'reloadData');
  const [isExpanded, setIsExpanded] = _useState(false,'isExpanded');
  const [selections, setSelections] = _useState({},'selections');

  const _toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const forceReloadData = () => {
    _setReloadData(reloadData + 1);
  };

  React.useEffect(
    () => {
      const loadData = async () => {
        setIsDataLoading(true);
        const finalSelectionId = (reloadData == 0) ? value : null;
        const smartSelectRequest = new SmartSelectRequest(config,finalSelectionId);
        for (const [tierName,selectedId] of Object.entries(selections)) {
          if (selectedId) {
            smartSelectRequest.setTierSelection(tierName,selectedId);
          }
        }
        const result = await SmartSelectService.lookup(smartSelectRequest);
        const data = result['data'];
        setData(data);
        // find last tier and selected value, then `setValue` using corresponding option data
        const {selected,options} = data.tiers[data.tiers.length-1];
        let selectedValue;
        for (let i=0; i<options.length; i++) {
          if (options[i].id === selected) {
            selectedValue = options[i];
            break;
          }
        }
        if (!value || selectedValue.id !== value.id) {
          setValue(selectedValue);
        }
        setIsDataLoading(false);
      };
      loadData().catch(console.error);
    }, [reloadData]
  );

  // placeholder div that expands the smart selection section when clicked
  const hasError = !!errorMessage;
  const _value = isDataLoading ? "Loading..." : data['name'];

  const placeHolder = <TextField
    label={title}
    fullWidth
    variant="filled"
    value={_value}
    error={hasError}
    helperText={hasError ? errorMessage : null}
    InputLabelProps={{
      shrink: true,
      sx: {
        marginTop: 0,
      }
    }}
    onClick={_toggleExpanded}
  />;

  const _setSelections = (type,selectedOption) => {
    const newSelections = {};
    newSelections[type] = selectedOption.id;
    setSelections(newSelections);
    forceReloadData();
  };
  const smartSelectSectionStyle = {
    transition: "height 300ms",
  };
  if (!isExpanded) {
    smartSelectSectionStyle.display = "none";
  }
  const selects = [];
  const len = data && data.tiers ? data.tiers.length : 0;
  for (let i=0; i<len; i++) {
  //     { type: "client", selected: 5, options: [ {id:1,name:"SAMI"},
  //                                               {id:5,name:"OtherClient"} ] },
    const {type,selected,options} = data.tiers[i];
    let selectedOption;
    if (selected) {
      selectedOption = options.find(el => el.id == selected);
    } else {
      selectedOption = options[0];
    }
    const _title = type.replace('_',' ').split(' ')
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      }).join(' ');
    const select = <RubsSelect
      selectOptions={options}
      title={_title}
      index={i}
      value={selectedOption}
      setValue={(newSelectedOption)=>_setSelections(type,newSelectedOption)}
    />;
    const renderedSelect = (
      <div style={{
        padding:"10px 0px 10px 50px"
      }}
           key={NamingThings.buildKey("smartSelectOption", title, i)}
      >
        {select}
      </div>
    );
    selects.push(renderedSelect);
  }
  return <div className="smartSelect"
              key={NamingThings.buildKey("smartSelectKey", title, index)}>
    {placeHolder}
    <div style={smartSelectSectionStyle}>
      {selects.map((select,index) => select)}
    </div>
  </div>;
};

export default RubsSmartSelect;