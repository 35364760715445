const billTargets = [
  {id:1,name:"Building"},
  {id:2,name:"Unit"},
];

class BillTargetTypeService {

  async get(id = null) {
    if (id) {
      return {data:billTargets[id-1]};
    } else {
      return {data:billTargets};
    }
  }

}

export default new BillTargetTypeService();