import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const RubsNumberInput = ({onValueChange, initialValue = 1, min=0,max=100}) => {
  const [value, setValue] = useState(initialValue.toFixed(2));

  const handleChange = (event) => {
    let _value = parseFloat(event.target.value);
    if (isNaN(_value)) {
      _value = initialValue;
    } else if (_value > max) {
      _value = max;
    } else if (_value < min) {
      _value = min;
    }
    _value = _value.toFixed(2);
    setValue(_value);
    onValueChange(_value);
  };

  return (
    <TextField
      type="number"
      value={value}
      inputProps={{
        step: 0.01,
        min: min,
        max: max,
        style: { textAlign: "right" }
      }}
      onChange={handleChange}
    />
  );
}

export default RubsNumberInput;