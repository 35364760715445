import BillsService from "../../../services/BillsService";
import AbstractMaintain from "./AbstractMaintain";
import UtilityAccountsService from "../../../services/UtilityAccountsService";

const MaintainBills = (props) => {
    const columnDefs = [
      {
        Header: "Account Identifier",
        accessor: "utility_account_id",
        type: "searchselect",
        lookupService: UtilityAccountsService,
        isEditable: true,
        isRequired: true,
        defaultValue: 1,
        isSearchable: true,
        optionMapFunction: UtilityAccountsService.optionMapFunction,
        searchSelectionMatcher: UtilityAccountsService.searchSelectionMatcher,
        postProcessor: async (origData) => {
          if (origData['start_date'] && origData['end_date'] && origData['bill_date']) {
            return origData;
          }
          if (!origData['utility_account_id']) {
            return origData;
          }
          const defaultDates = await BillsService.getDefaultDates(origData['utility_account_id']);
          if (!defaultDates) {
            return origData;
          }
          const data = {...origData};
          if (!data['start_date']) {
            data.start_date = defaultDates['start_date'];
          }
          if (!data['end_date']) {
            data.end_date = defaultDates['end_date'];
          }
          if (!data['bill_date']) {
            data.bill_date = defaultDates['bill_date'];
          }
          return data;
        },
      },
      {
        Header: "Account Detail",
        accessor: "derived_account_detail",
        type: "derived",
        isEditable: false,
        isSearchable: true,
        isRequired: false,
      },
      {
        Header: "Amount Due",
        accessor: "amount",
        type: "text",
        isEditable: true,
        isSearchable: true,
        isRequired: true,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        type: "yyyymmdd",
        isEditable: true,
        isSearchable: true,
      },
      {
        Header: "End Date",
        accessor: "end_date",
        type: "yyyymmdd",
        isEditable: true,
        isSearchable: true,
      },
      {
        Header: "Bill Date",
        accessor: "bill_date",
        type: "yyyymmdd",
        isEditable: true,
        isSearchable: true,
      },
      {
        Header: "Exported?",
        accessor: "export_status",
        type: "text",
        renderCell: (value) => {
          const displayVal = value ? 'Yes' : 'No';
          return <div>{displayVal}</div>;
        },
        isEditable: false,
        isSearchable: false,
      }
    ];
    return AbstractMaintain(props, {
      columnDefs,
      dataService: BillsService,
      dataDescriptionStr: "Bill",
      allowDelete: true,
    });
};

export default MaintainBills;