import PortfoliosService from "../../../services/PortfoliosService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainPortfolios = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Alternate Names",
      accessor: "alternate_names",
      type: "text",
      isEditable: true,
      isRequired: false,
      isSearchable: true,
    },
    {
      Header: "PropertyWare Name",
      accessor: "propertyware_name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: PortfoliosService,
    dataDescriptionStr: "Portfolio",
  });
};

export default MaintainPortfolios;
