import React from "react";
import {useState} from "../../../utils/ReactUtils";
import UsersService from "../../../services/UsersService";
import {Button} from "@mui/material";
import Message from "./etc/Message";
import TextInput from "./etc/TextInput";

const GeneralSettings = (props) => {
  const {userId} = props;

  const [isLoading,setIsLoading] = useState(true, 'isLoading_AdministerGeneralSettings');
  const [isSaving,setIsSaving] = useState(false, 'isSaving_AdministerGeneralSettings');
  const [login,setLogin] = useState(null, "login");
  const [firstName,setFirstName] = useState(null, "firstName");
  const [lastName,setLastName] = useState(null, "lastName");
  const [message,setMessage] = useState(null, 'message');
  const [isError,setIsError] = useState(false, "isError");

  const setError = msg => {setMessage(msg); setIsError(true)};
  const setInfo = msg => {setMessage(msg); setIsError(false)};
  const resetMessage = () => {setMessage(null); setIsError(null);};

  React.useEffect(() => {
    if (isSaving || !isLoading) return;
    const loadUser = async () => {
      const user = (await UsersService.get(userId))["data"];
      setLogin(user['login']);
      setFirstName(user['firstName']);
      setLastName(user['lastName']);
      setIsLoading(false);
    };
    loadUser().catch(console.error);
  },[isLoading]);

  const doSave = async () => {
    resetMessage();
    if (!firstName || !lastName || !login) {
      setError("All fields are required")
    } else if (login.length < 5) {
      setError("Login must be at least 5 characters")
    } else {
      setIsSaving(true);
      const result = (await UsersService.updateGeneralInfo(userId, login, firstName, lastName))["data"];
      setIsSaving(false);
      console.log('result',result);
      if (result['success']) {
        setInfo(result['message']);
      } else {
        setError(result['message']);
      }
      setIsLoading(true);
    }
  };

  let result;
  if (isSaving) {
    result = <div>Saving...</div>;
  } else if (isLoading) {
    result = <div>Loading...</div>
  } else {
    result =
      <div>
        <div>
          <TextInput title="Login" index="1" value={login} setValue={setLogin} />
          <TextInput title="First Name" index="2" value={firstName} setValue={setFirstName} />
          <TextInput title="Last Name" index="3" value={lastName} setValue={setLastName} />
          {/*<FormControlLabel control={*/}
          {/*  <Checkbox checked={isAdmin}*/}
          {/*            onChange={e=>setIsAdmin(!isAdmin)}*/}
          {/*  />} label="Is Admin?" />*/}
        </div>
        <div style={{padding:"20px 30px 0px 0px", float:"right"}}>
          <Button onClick={doSave}>Save</Button>
        </div>
      </div>
    ;
  }
  return <div style={{borderStyle:"outset",
    padding:"10px",
    minHeight:"100%",
  }}>
    <h4>General Settings</h4>
    <Message message={message} isError={isError} />
    {result}
  </div>;
};

export default GeneralSettings;