import ProvidersService from "../../../services/ProvidersService";
import AbstractMaintain from "./AbstractMaintain";

const MaintainProviders = (props) => {
  const columnDefs = [
    {
      Header: "Name",
      accessor: "name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "PropertyWare Name",
      accessor: "propertyware_name",
      type: "text",
      isEditable: true,
      isRequired: true,
      isSearchable: true,
    },
    {
      Header: "Default 'start_date' offset from current date (e.g. -50)",
      accessor: "default_start_date_offset_from_current_date",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
    {
      Header: "Default 'bill_date' offset from 'end_date' (e.g. 10)",
      accessor: "default_bill_date_offset_from_end_date",
      type: "text",
      isEditable: true,
      isRequired: true,
    },
  ];
  return AbstractMaintain(props, {
    columnDefs,
    dataService: ProvidersService,
    dataDescriptionStr: "Provider",
  });
};

export default MaintainProviders;